@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.ducnotes{
  /*background-color: rgba(48, 47, 47, 0.849);*/
}

.container {
  margin: 0 !important;
  padding-left: 64px;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding-bottom: 20px;
}

.content {
  height: calc(100vh - 50px);
}

.signIn{
  height: 100vh;
}

.btn {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;

}

.task-check{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 12px;
}
.task-content{
  flex: 1;
}

.taskParent>.task:nth-child(odd){
  background-color: rgb(239, 172, 241);
}

.taskwrapper-scroll{
  height: 100%;
  width: 100%;
  overflow: hidden;
}


.tasksContainer{
  height: 100%;
  overflow-y: scroll;
}

.task.done {
  /*background-color: rgb(98, 32, 253);*/
  opacity: 0.3;
  text-decoration: line-through;
}

.taskText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.task input{
  width: 100%;
  border: none;
}

.task input:disabled{
  background-color: rgba(0,0,0,0);
}

.taskDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
}




.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
  display: flex;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

#image-input-tag{
  display: none;
}

.list-image-tag{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}


/* Navigation Bar*/
.nav-toggle {
  position: fixed;
  top: 0px;
  left: -100%;
  font-size: 40px;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: .9;
  text-decoration: none;
  transition: 1s;
}

.nav-toggle-open {
  left: 0%;
}

.nav_list {
  height: 100%;
  list-style: none;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
}

.nav_item{
  margin: 20px;
  color: rgb(32, 87, 206);
}

.nav_item>div>a{
  text-decoration: none;
  color: inherit;
}

.nav_item>div>a:hover{
  text-decoration: none;
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 mb-2 mx-auto shadow-lg
     bg-gray-800 text-green-500 hover:bg-green-600 hover:text-white rounded-3xl 
     hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
    text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-left;
  }

  .addTaskButton-icon { 
    @apply mb-5 dark:text-white text-3xl cursor-pointer dark:hover:text-green-500
    dark:hover:bg-white rounded-3xl hover:text-green-500 transition-all duration-300
    ease-linear;
  }

  .sidebar-image{
    @apply hover:rounded-xl rounded-3xl transition-all duration-300 ease-linear
  }

}


.btn-menu{
  position: fixed;
  left: 0px;
  bottom:0px;
  z-index: 1;
  width: 80px;
  margin: 20px;
  font-size: 20px;
}


footer {
  margin-top: 30px;
  text-align: center;
}


.poly{
  aspect-ratio: 16/9;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wave-blue-bottom{
  background-image: url('./assets/images/wave-blue-bottom.svg');
}

.description-1{
  border-radius: 30% 70% 60% 40% / 30% 28% 72% 70% ;
}

.description-2{
  border-radius:16% 84% 6% 94% / 51% 89% 11% 49%  ;
}

.description-3{
  border-radius: 86% 14% 87% 13% / 68% 45% 55% 32% ;
}

.description-4{
  border-radius: 45% 55% 18% 82% / 48% 45% 55% 52% ;
}

.description{
  padding: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(29, 78, 216, 0.5 );
  max-width: 50%;
  font-size: calc(5px + 0.5vw);

}

.signInContent{
  width: 30vw;
  min-width: 300px;
}